import React from "react";
import About from "../components/About/About";
import Seo from "../components/Seo/seo";

const AboutPage = () => {
  return (
    <>
      <Seo title="About" description="About page for Goriot" />
      <About />
    </>
  );
};

export default AboutPage;
