import React from "react";

import * as classes from "./About.module.css";

const About = () => {
  return (
    <div className={classes.aboutContainer}>
      <p>
        It is easy in the world to live after the world's opinion; it is easy in
        solitude to live after our own; but the great man is he who in the midst
        of the crowd keeps with perfect sweetness the independence of solitude -
        Ralph Waldo Emerson (1841).
      </p>

      <p>
        In this world we’re pulled in countless directions. The most interesting
        people are those that can resist conformity. We believe in forming your
        own opinions and to value truth above politeness.
      </p>
      <p>
        We hope to tell stories of these interesting people, through quality
        clothing.
      </p>
    </div>
  );
};

export default About;
